import { getSession } from "next-auth/client";
import { useRouter } from "next/router";
import { useState } from "react";
import { Favorites } from "repositories";
import { commonErrorMessage } from "shared/hooks/useErrorMessage";
import { useFlashContext } from "shared/hooks/useFlashContext";
import { Fortune, TelFortune, menuTypes } from "shared/types";
import { Paths } from "utils/routes";

interface UseFavorite {
  onClickFavorite: (menu: Fortune | TelFortune) => void;
  disabled: boolean;
}

export const useFavorite = (onSuccess?: () => void): UseFavorite => {
  const [disabled, setDisabled] = useState(false);

  const { push } = useRouter();
  const { toggleFlash } = useFlashContext();

  const onClickFavorite = async (menu: Fortune | TelFortune) => {
    setDisabled(true);

    const session = await getSession();
    if (!session) {
      push(Paths.users.signIn.index());
      return;
    }

    const favoritableType =
      menu.type === "chat" ? menuTypes.chat : menuTypes.tel;

    try {
      if (menu.isFavorited) {
        await Favorites.delete({
          favoritableType,
          favoritableId: menu.id,
        });
      } else {
        await Favorites.post({
          favoritableType,
          favoritableId: menu.id,
        });
      }

      if (onSuccess) onSuccess();
    } catch (e) {
      toggleFlash({ message: commonErrorMessage });
    } finally {
      setDisabled(false);
    }
  };

  return {
    onClickFavorite,
    disabled,
  };
};
