import { Carousel } from "shared/components/atoms/Carousel";
import { ImageCard } from "components/molecules/ImageCard";
import Link from "next/link";
import { useTagList } from "repositories/hooks/useTagList";
import { Tag } from "shared/types";
import tw from "twin.macro";
import { Paths } from "utils/routes";

const pickupTagIds = [7, 3, 9, 4, 17, 25, 6, 2, 11, 19];

const imageCardCss = { title: tw`text-xl` };

interface Props {
  className?: string;
}

export const SceneTagCardList = ({ className }: Props) => {
  const { tags } = useTagList({
    meta_description_type: "scene",
    ids: pickupTagIds.join(","),
  });

  const pickupTags = pickupTagIds
    .map((id) => tags.find((tag) => tag.id === id))
    .filter(Boolean);

  const renderSceneTagCard = (tag: Tag) => (
    <Link href={Paths.tags.show(tag.id)}>
      <a>
        <ImageCard
          src={tag.bannerUrl}
          title={tag.name}
          subTitle={`${tag.menuCounts.toLocaleString()}件のメニュー`}
          width={130}
          height={75}
          classes={imageCardCss}
        />
      </a>
    </Link>
  );

  return (
    <div className={className}>
      <h2 tw="font-base text-lg tracking-[-0.17px] leading-[18px] mx-5">
        様々な悩みに対応した
        <div tw="mt-4">占いメニューが出品されています</div>
      </h2>
      <Carousel data={pickupTags} renderItem={renderSceneTagCard} />
    </div>
  );
};
