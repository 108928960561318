import { Api, Tags } from "@miror/types";
import { httpClients } from "lib/axios";
import useSWR from "swr";

const api = new Api(httpClients.miror);

export const useTagList = (query: Tags.TagsList.RequestQuery) => {
  const { data, error, mutate } = useSWR(
    ["/tags", ...Object.values(query)],
    async () => api.tags.tagsList(query).then((res) => res.data),
    { fallbackData: { tags: [] } }
  );

  return {
    tags: data?.tags,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
