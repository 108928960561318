import { repository } from "repositories";
import {
  GetFreeFortuneContentListParams,
  GetFreeFortuneContentListRes,
  SWRResponse,
} from "shared/types";
import useSWR from "swr";

export const useFreeFortuneContentList = (
  params?: GetFreeFortuneContentListParams
): SWRResponse<GetFreeFortuneContentListRes> => {
  const repo = repository.freeFortuneContent.getList(params);

  const { data, error, mutate } = useSWR(
    params ? [repo.path, ...Object.values(params)] : repo.path,
    repo.data
  );

  return {
    ...data,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
