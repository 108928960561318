import { FC, useMemo, ReactNode } from "react";
import styled from "styled-components";

import { Colors } from "../../styles";
import { TextAlign } from "../../types";

const Component = styled.span<Props>`
  font-weight: ${(props) => props.fontWeight || 300};
  font-size: ${(props) => props.fontSize || 12}px;
  letter-spacing: ${(props) => props.letterSpacing || 0}px;
  line-height: ${(props) => props.lineHeight || props.fontSize || 12}px;
  color: ${(props) => props.color || Colors.black["01"]};
  text-align: ${(props) => props.align || "left"};
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
`;

export interface Props {
  className?: string;
  fontWeight?: number;
  fontSize?: number;
  letterSpacing?: number;
  lineHeight?: number;
  color?: string;
  maxLength?: number;
  align?: TextAlign;
  style?: Record<string, unknown>;
  variant?: string;
  omission?: string | ReactNode;
  // TODO: ちゃんと型付けする
  as?: any;
}

/**
 * @param fontWeight [number] default: 300
 * @param fontSize [number] default: 12
 * @param letterSpacing [number] default: 0
 * @param lineHeight [number] default: 12
 * @param color [string] default: #3C424A
 * @param align [left | center | right] default: left
 */
export const Typography: FC<Props> = (props) => {
  const { children, maxLength, omission: customOmission, ...rest } = props;

  const text = useMemo(() => {
    if (typeof children === "string" && maxLength) {
      const truncated = children.slice(0, maxLength);
      const omission =
        children.length > maxLength ? (
          customOmission ? (
            <>...{customOmission}</>
          ) : (
            "..."
          )
        ) : (
          ""
        );
      return (
        <span tw="inline">
          {truncated}
          {omission}
        </span>
      );
    } else {
      return children;
    }
  }, [maxLength, children, customOmission]);

  return <Component {...rest}>{text}</Component>;
};
