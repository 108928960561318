import { FC, HTMLAttributes, ReactNode } from "react";
import tw from "twin.macro";

import ArrowIcon from "../../assets/icons/RightArrow.svg";
import { Colors } from "../../styles";
import { KeyValue } from "../../types";

const listItemCss = {
  paddingBottom: 20,
  ":nth-child(odd)": { paddingRight: 8 },
  ":nth-child(even)": { paddingLest: 8 },
};

const multiColumnCss = {
  ":nth-child(odd)": { borderRight: `1px solid ${Colors.blue["01"]}` },
};

interface TitleProps {
  title?: string;
  titleProps?: HTMLAttributes<HTMLElement>;
  renderTitle?: ReactNode;
  className?: string;
}

interface Props<T> extends TitleProps {
  data: Array<T>;
  keyName?: string;
  columns?: number;
  onClick?: (data: T, index: number) => void;
  renderItem?: (data: T, index: number) => ReactNode;
  firstItem?: ReactNode;
  lastItem?: ReactNode;
  emptyLayout?: ReactNode;
  className?: string;
  /** 意味分からんので消したい */
  itemPadding?: boolean;
  /** classNameで上書きできるはずなので消したい */
  bottomBorder?: boolean;
}

function SimpleList<T extends KeyValue>({
  data,
  keyName,
  columns = 1,
  onClick,
  renderItem,
  firstItem,
  lastItem,
  emptyLayout,
  className,
  itemPadding = false,
  bottomBorder = true,
}: Props<T>) {
  const listStyle = [
    itemPadding && listItemCss,
    { width: `${100 / columns}%` },
  ];
  const isEmpty = (!data || data.length === 0) && !firstItem && !lastItem;

  const handleClickItem = (data: T, index: number) => {
    if (onClick) {
      onClick(data, index);
    }
  };

  if (isEmpty) {
    return (
      <div
        tw="w-full flex flex-wrap"
        css={bottomBorder && tw`border-b border-solid border-gray`}
        className={className}
      >
        {emptyLayout || null}
      </div>
    );
  }

  return (
    <ul
      tw="w-full flex flex-wrap"
      css={bottomBorder && tw`border-b border-solid border-gray`}
      className={className}
    >
      {firstItem && <li css={listStyle}>{firstItem}</li>}
      {data.map((row, index) =>
        renderItem ? (
          <li key={index} css={listStyle}>
            {renderItem(row, index)}
          </li>
        ) : (
          <li
            key={`${row[keyName]}-${index}`}
            tw="border-t border-solid border-gray h-10 px-6 flex justify-between items-center"
            css={[listStyle, columns > 1 && multiColumnCss]}
            onClick={() => handleClickItem(row, index)}
          >
            <span tw="text-xs font-normal cursor-pointer text-left text-secondary">
              {row[keyName]}
            </span>
            <ArrowIcon tw="ml-1" />
          </li>
        )
      )}
      {lastItem && <li css={listStyle}>{lastItem}</li>}
    </ul>
  );
}

const WithTitle: FC<TitleProps> = ({
  title,
  titleProps,
  renderTitle,
  className,
  children,
}) => {
  return (
    <div tw="w-full mb-4" className={className}>
      {!renderTitle && title ? (
        <h4 tw="font-base text-lg tracking-[.17px] pb-4 px-5" {...titleProps}>
          {title}
        </h4>
      ) : (
        renderTitle
      )}
      {children}
    </div>
  );
};

export function List<T extends KeyValue>({
  title,
  renderTitle,
  className,
  ...props
}: Props<T>) {
  if (title || renderTitle) {
    return (
      <WithTitle
        title={title}
        renderTitle={renderTitle}
        className={className}
        {...props}
      >
        <SimpleList {...props} />
      </WithTitle>
    );
  } else {
    return <SimpleList className={className} {...props} />;
  }
}
