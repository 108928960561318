import { Image } from "shared/components/atoms/Image";
import { CSSProp } from "styled-components";

interface Props {
  src: string;
  width: number;
  height: number;
  title?: string;
  subTitle?: string;
  classes?: {
    title: CSSProp;
  };
  onClick?: () => void;
}

export const ImageCard = ({
  src,
  width,
  height,
  title,
  subTitle,
  classes,
  onClick,
}: Props) => {
  return (
    <button
      type="button"
      tw="flex-center relative overflow-hidden"
      onClick={onClick}
    >
      <Image
        tw="rounded-xl"
        src={src}
        width={width}
        height={height}
        layout="fixed"
        objectFit="cover"
      />
      <div tw="absolute w-full p-2 box-border">
        {title && (
          <h6
            tw="font-hira-mincho font-semibold text-sm text-white"
            css={classes?.title}
          >
            {title}
          </h6>
        )}
        {subTitle && (
          <p tw="font-semibold text-2xs text-white mt-1">{subTitle}</p>
        )}
      </div>
    </button>
  );
};
