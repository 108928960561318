import dayjs from "dayjs";
import { FreeFortuneContent } from "shared/types";

export const useFreeFortuneContentFormatDate = (
  freeFortuneContent: FreeFortuneContent
) => {
  const lastmod = dayjs(freeFortuneContent.lastmod);

  return {
    lastmod: lastmod.isValid() ? lastmod.format("YYYY-MM-DD") : null,
  };
};
