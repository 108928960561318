import { Carousel } from "shared/components/atoms/Carousel";
import { H2 } from "components/atoms/H2";
import { ImageCard } from "components/molecules/ImageCard";
import { Tag } from "shared/types";
import { Paths } from "utils/routes";
import Link from "next/link";
import { useDivinationTagList } from "repositories/hooks/useDivinationTagList";

const pickupTagIds = [35, 40, 39, 46, 36, 41, 38, 42, 58, 48];

interface Props {
  className?: string;
}

export const DivinationTagCardList = ({ className }: Props) => {
  const { divinationTags } = useDivinationTagList();

  const pickupDivinationTags = pickupTagIds
    .map((id) => divinationTags.find((tag) => tag.id === id))
    .filter(Boolean);

  const renderDivinationTagCard = (tag: Tag) => (
    <Link href={Paths.tags.show(tag.id)}>
      <a>
        <ImageCard
          src={tag.bannerUrl}
          title={tag.name}
          subTitle={`${tag.menuCounts}件のメニュー`}
          width={120}
          height={160}
        />
      </a>
    </Link>
  );

  return (
    <div className={className}>
      <H2 tw="mx-5">得意な占術から選ぶ</H2>
      <Carousel
        data={pickupDivinationTags}
        renderItem={renderDivinationTagCard}
      />
    </div>
  );
};
