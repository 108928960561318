import Link from "next/link";
import { useRouter } from "next/router";
import { HTMLAttributes } from "react";
import { useCategories } from "repositories/hooks";
import { useDivinationTagList } from "repositories/hooks/useDivinationTagList";
import { List } from "shared/components/molecules/List";
import { Tag } from "shared/types";
import { Paths } from "utils/routes";

export const CategoryAndTagList = ({
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  const { divinationTags } = useDivinationTagList();
  const { categories } = useCategories();
  const router = useRouter();
  const goToTag = (tag: Tag) => router.push(`/tags/${tag.id}`);

  return (
    <div {...rest}>
      <List
        title="占術一覧"
        data={divinationTags}
        keyName="name"
        columns={2}
        onClick={goToTag}
      />
      <List
        tw="mt-9"
        title="占い相談ジャンル一覧"
        data={categories}
        renderItem={(category) => (
          <List
            renderTitle={
              <Link href={Paths.categories.index(category.alias)} passHref>
                <a tw="font-base text-sm inline-block pb-3 px-5 mt-4">
                  {category.name}
                </a>
              </Link>
            }
            data={category.tags}
            keyName="name"
            columns={2}
            onClick={goToTag}
          />
        )}
        bottomBorder={false}
      />
    </div>
  );
};
