import { useActiveCampaign } from "features/campaigns/hooks/useActiveCampaign";
import {
  useFortuneTellerList,
  useFortunes,
  useTagFortuneList,
  useTelFortunes,
} from "repositories/hooks";
import { menuTypesV2, FortuneTeller, Fortune, TelFortune } from "shared/types";

interface UseFetchDataReturn {
  waitingChatFortuneTellers: FortuneTeller[];
  recommendChatFortuneTellers: FortuneTeller[];
  campaignTelFortunes: TelFortune[];
  popularTelFortunes: TelFortune[];
  waitingTelFortuneTellers: FortuneTeller[];
  popularFortunes: Fortune[];
  furinFortunes: Fortune[];
  fukuenFortunes: Fortune[];
  miraiFortunes: Fortune[];
  konkiFortunes: Fortune[];
  isLoadingWaitingChatFortuneTellers: boolean;
  isLoadingRecommendChatFortuneTellers: boolean;
  isLoadingTelFortunes: boolean;
  isLoadingWaitingTelFortuneTellers: boolean;
  isLoadingPopularFortunes: boolean;
  isLoadingFurinFortunes: boolean;
  isLoadingFukuenFortunes: boolean;
  isLoadingMiraiFortunes: boolean;
  isLoadingKonkiFortunes: boolean;
}

const waitingChatFortuneTellerParams = {
  menuType: menuTypesV2.chat,
  waiting: "waiting" as const,
  limit: 30,
  shuffle: true,
};

const recommendChatFortuneTellersParams = {
  menuType: menuTypesV2.chat,
  orderBy: "repeat_rate" as const,
  limit: 30,
  shuffle: true,
};

const useFetchData = (): UseFetchDataReturn => {
  const {
    fortuneTellers: waitingChatFortuneTellers,
    isLoading: isLoadingWaitingChatFortuneTellers,
  } = useFortuneTellerList(waitingChatFortuneTellerParams);

  const {
    fortuneTellers: recommendChatFortuneTellers,
    isLoading: isLoadingRecommendChatFortuneTellers,
  } = useFortuneTellerList(recommendChatFortuneTellersParams);

  const { campaign } = useActiveCampaign();
  const campaignTelFortunes =
    campaign?.fortuneTellers?.map((fortuneTeller) => ({
      ...fortuneTeller.bestPriceTelFortune,
      fortuneTeller,
    })) ?? [];

  const { telFortunes: popularTelFortunes, isLoading: isLoadingTelFortunes } =
    useTelFortunes({ limit: 10 });

  const {
    fortuneTellers: waitingTelFortuneTellers,
    isLoading: isLoadingWaitingTelFortuneTellers,
  } = useFortuneTellerList({
    menuType: "tel",
    waiting: "waiting",
    limit: 30,
    shuffle: true,
  });

  const { fortunes: popularFortunes, isLoading: isLoadingPopularFortunes } =
    useFortunes();

  const { fortunes: furinFortunes, isLoading: isLoadingFurinFortunes } =
    useTagFortuneList({
      tagId: 9,
      orderBy: "popular",
      limit: 10,
      shuffle: true,
    });

  const { fortunes: fukuenFortunes, isLoading: isLoadingFukuenFortunes } =
    useTagFortuneList({
      tagId: 7,
      orderBy: "popular",
      limit: 10,
      shuffle: true,
    });

  const { fortunes: miraiFortunes, isLoading: isLoadingMiraiFortunes } =
    useTagFortuneList({
      tagId: 6,
      orderBy: "popular",
      limit: 10,
      shuffle: true,
    });

  const { fortunes: konkiFortunes, isLoading: isLoadingKonkiFortunes } =
    useTagFortuneList({
      tagId: 17,
      orderBy: "popular",
      limit: 10,
      shuffle: true,
    });

  return {
    waitingChatFortuneTellers,
    recommendChatFortuneTellers,
    campaignTelFortunes,
    popularTelFortunes,
    waitingTelFortuneTellers,
    popularFortunes,
    furinFortunes,
    fukuenFortunes,
    miraiFortunes,
    konkiFortunes,
    isLoadingWaitingChatFortuneTellers,
    isLoadingRecommendChatFortuneTellers,
    isLoadingTelFortunes,
    isLoadingWaitingTelFortuneTellers,
    isLoadingPopularFortunes,
    isLoadingFurinFortunes,
    isLoadingFukuenFortunes,
    isLoadingMiraiFortunes,
    isLoadingKonkiFortunes,
  };
};

export default useFetchData;
