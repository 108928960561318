import { Carousel } from "shared/components/atoms/Carousel";
import { H2 } from "components/atoms/H2";
import { useFavorite } from "features/shared/hooks/useFavorite";
import useFetchData from "features/Home/hooks/useFetchData";
import { TelFortune, Fortune } from "shared/types";
import tw from "twin.macro";
import Link from "next/link";
import { menuHelper } from "features/menu/utils/menuHelper";
import { MenuGallelyCard } from "shared/features/Menu/components/MenuGallelyCard";
import { Paths } from "utils/routes";

const StyledH2 = tw(H2)`px-5 mt-8`;

const TagMenus = () => {
  const {
    popularFortunes,
    furinFortunes,
    fukuenFortunes,
    miraiFortunes,
    konkiFortunes,
  } = useFetchData();
  const { onClickFavorite } = useFavorite();

  const renderMenuCard = (menu: Fortune | TelFortune) => {
    const { menuPagePath } = menuHelper(menu);
    return (
      <Link href={menuPagePath}>
        <a>
          <MenuGallelyCard menu={menu} onClickFav={onClickFavorite} />
        </a>
      </Link>
    );
  };

  return (
    <>
      <StyledH2>人気のチャット占い</StyledH2>
      <Carousel data={popularFortunes} renderItem={renderMenuCard} />
      <StyledH2 readMore href={Paths.tags.fortunes(9)}>
        不倫に関する人気メニュー
      </StyledH2>
      <Carousel data={furinFortunes} renderItem={renderMenuCard} />
      <StyledH2 readMore href={Paths.tags.fortunes(7)}>
        復縁に関する人気メニュー
      </StyledH2>
      <Carousel data={fukuenFortunes} renderItem={renderMenuCard} />
      <StyledH2 readMore href={Paths.tags.fortunes(6)}>
        ２人の未来に関する人気メニュー
      </StyledH2>
      <Carousel data={miraiFortunes} renderItem={renderMenuCard} />
      <StyledH2 readMore href={Paths.tags.fortunes(17)}>
        婚期に関する人気メニュー
      </StyledH2>
      <Carousel data={konkiFortunes} renderItem={renderMenuCard} />
    </>
  );
};

export default TagMenus;
