import { useFreeFortuneContentList } from "repositories/hooks/useFreeFortuneContentList";
import ContentsCard from "features/無料占いコンテンツ/components/organisms/ContentsCard";

interface Props {
  className?: string;
}

export const FreeFortuneContentList = ({ className }: Props) => {
  const { freeFortuneContents } = useFreeFortuneContentList({
    page: 1,
    limit: 10,
  });

  return (
    <ul tw="grid grid-flow-row gap-y-2" className={className}>
      {freeFortuneContents?.map((freeFortuneContent) => (
        <li key={freeFortuneContent.id}>
          <ContentsCard freeFortuneContent={freeFortuneContent} />
        </li>
      ))}
    </ul>
  );
};
