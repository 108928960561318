import { Api } from "@miror/types";
import { httpClients } from "lib/axios";
import useSWRImmutable from "swr/immutable";

const api = new Api(httpClients.miror);

const TAG_TYPE = "divination";

export const useDivinationTagList = () => {
  const { data, error, mutate } = useSWRImmutable(
    ["/tags", TAG_TYPE],
    async () =>
      api.tags.tagsList({ variant: TAG_TYPE }).then((res) => res.data),
    { fallbackData: { tags: [] } }
  );

  return {
    divinationTags: data?.tags,
    isLoading: !data && !error,
    isError: !!error,
    mutate,
  };
};
