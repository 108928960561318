import { useTopics } from "repositories/hooks";
import { Media, Order } from "repositories/TopicRepository";
import { Button } from "shared/components/atoms/Button";
import { List } from "shared/components/molecules/List";
import { ListItem } from "shared/components/molecules/ListItem";
import { Topic } from "shared/types";
import { Colors } from "shared/styles";
import tw from "twin.macro";
import { useRouter } from "next/router";
import { Paths } from "utils/routes";
import { Sentry } from "lib/sentry";
import dayjs from "dayjs";

const useStyles = () => {
  return {
    button: [tw`w-full rounded h-12`, `background: transparent`],
    buttonLabel: [
      tw`font-normal`,
      `
      font-size: 14px;
      color: #E271B6;
      letter-spacing: 0.41px;
      text-align: center;
    `,
    ],
  };
};

export const TopicList = () => {
  const { topics: pressTopics } = useTopics(Media.press, {
    limit: 5,
    order: Order.lastmod,
    meta: false,
  });
  const { topics: miracleTopics } = useTopics(Media.miracle, {
    limit: 5,
    order: Order.lastmod,
    meta: false,
  });
  const { push } = useRouter();

  const goToPress = async () => {
    try {
      await push(Paths.press.list());
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const goToMiracle = async () => {
    try {
      await push(Paths.miracle.list());
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const renderTopic = (topic: Topic) => {
    const publishedAt = dayjs(topic.lastmod).format("YYYY/MM/DD HH:mm");

    const goToTopic = async () => {
      if (topic.mediaType === "miracle") {
        await push(Paths.miracle.column(topic.id));
      } else if (topic.mediaType === "press") {
        await push(Paths.press.column(topic.id));
      } else {
        Sentry.captureMessage(
          `Unsupported mediaType '${topic.mediaType}' detected.`
        );
      }
    };

    return (
      <ListItem
        image={`https://cdn.dears.media/thumbnail/small/small_${topic.filename}`}
        title={topic.title}
        text={publishedAt}
        onClick={goToTopic}
        tw="py-4 px-5 border-none"
        css={[`border-top: 1px solid ${Colors.blue["01"]}`]}
      />
    );
  };

  const classes = useStyles();
  return (
    <>
      <List
        tw="mt-8"
        title="新着恋愛コラム"
        data={pressTopics}
        renderItem={renderTopic}
      />
      <div tw="mt-4 px-5">
        <Button css={classes.button} variant="outline" onClick={goToPress}>
          <span css={classes.buttonLabel}>恋愛コラムをもっと見る</span>
        </Button>
      </div>
      <List
        tw="mt-8"
        title="新着占いコラム"
        data={miracleTopics}
        renderItem={renderTopic}
      />
      <div tw="mt-4 px-5">
        <Button css={classes.button} variant="outline" onClick={goToMiracle}>
          <span css={classes.buttonLabel}>占いコラムをもっと見る</span>
        </Button>
      </div>
    </>
  );
};
