import React from "react";

import { LastModLabel } from "features/無料占いコンテンツ/components/atoms/LastModLabel";
import { CategoryLabel } from "features/無料占いコンテンツ/components/atoms/CategoryLabel";
import Link from "next/link";
import { FreeFortuneContent } from "shared/types";
import { useFreeFortuneDivinationNameAlias } from "features/無料占いコンテンツ/hooks/useFreeFortuneDivinationNameAlias";
import { useFreeFortuneContentFormatDate } from "features/無料占いコンテンツ/hooks/useFreeFortuneContentFormatDate";
import { Paths } from "utils/routes";
import { Image } from "shared/components/atoms/Image";

interface Props {
  freeFortuneContent: FreeFortuneContent;
}

const ContentsCard = ({ freeFortuneContent }: Props) => {
  const { alias } = useFreeFortuneDivinationNameAlias(
    freeFortuneContent.freeFortuneDivination
  );

  const { lastmod } = useFreeFortuneContentFormatDate(freeFortuneContent);

  return (
    <Link
      href={Paths.freeFortuneContents.show(alias, freeFortuneContent.id)}
      passHref
    >
      <a
        tw="grid grid-flow-col gap-x-2 py-3 border-b border-solid border-gray"
        css={{ gridTemplateColumns: "auto 1fr" }}
      >
        {freeFortuneContent.imageUrl && (
          <Image
            src={freeFortuneContent.imageUrl}
            width={119}
            height={60}
            alt={`${freeFortuneContent.title}のアイキャッチ画像`}
            layout="fixed"
            objectFit="cover"
            tw="rounded"
          />
        )}
        <div tw="grid grid-flow-row">
          <h5 tw="font-base text-[11px] tracking-[.06] leading-4">
            {freeFortuneContent.title}
          </h5>
          <div tw="grid grid-flow-col justify-between font-base text-[8px] tracking-[.04]">
            <CategoryLabel
              category={freeFortuneContent.freeFortuneCategory}
              linkable={false}
              tw="mr-2.5"
            />
            <LastModLabel>{lastmod}</LastModLabel>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default ContentsCard;
