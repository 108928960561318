import { FreeFortuneDivination } from "shared/types";

export const useFreeFortuneDivinationNameAlias = (
  divination: FreeFortuneDivination
) => {
  if (!divination) {
    return { alias: "" };
  } else if (divination.name === "タロット") {
    return { alias: "tarot" };
  } else if (divination.name === "生年月日") {
    return { alias: "birthday" };
  } else {
    return { alias: "" };
  }
};
