import { Fortune, TelFortune } from "shared/types";

export const menuHelper = (menu: Fortune | TelFortune | undefined) => ({
  canOrder: () => {
    const fortuneTeller = menu?.fortuneTeller;

    if (!menu?.isPublished) return false;

    if (menu?.type === "chat") {
      return fortuneTeller?.chatAcceptFlag && !fortuneTeller?.isBusy;
    }

    if (menu?.type === "tel") {
      return fortuneTeller?.telAcceptFlag;
    }

    return undefined;
  },
  menuPagePath:
    menu?.type === "chat"
      ? `/fortunes/${menu?.id}`
      : `/tel_fortunes/${menu?.id}`,
  orderPagePath:
    menu?.type === "chat"
      ? `/order/chat/menu/${menu?.id}/profile`
      : `/order/tel/menu/${menu?.id}/profile`,
});
