import React from "react";

import { HeartIcon } from "public/media/icons";
import { FreeFortuneCategory } from "shared/types";
import Link from "next/link";

interface Props {
  category?: FreeFortuneCategory;
  linkable: boolean;
  className?: string;
}

export const CategoryLabel = ({ category, linkable, className }: Props) => {
  if (!category) return null;

  return (
    <div tw="flex items-center" className={className}>
      <HeartIcon />
      <div tw="font-base text-[11px] tracking-[.06px] ml-[5px]">
        {linkable ? (
          <Link href={`/free_fortune/category/${category.id}`}>
            {`${category.name}占い`}
          </Link>
        ) : (
          `${category.name}占い`
        )}
      </div>
    </div>
  );
};
