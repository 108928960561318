import Link from "next/link";
import { HTMLAttributes } from "react";
import tw from "twin.macro";
import { Paths } from "utils/routes";

const useStyles = () => {
  return {
    card: `
      padding: 14px 13px;
      background: linear-gradient(-44deg, #B647B7 0%, #F683B6 100%);
      border-radius: 13px;
      display: flex;
      position: relative;
    `,
    text: tw`font-semibold`,
    text1: `margin-bottom: 10px`,
    text2: `letter-spacing: 1.12px`,
    text3: `
      font-size: 13px;
      background: #fff;
      color: #CB71B3;
      padding: 10px 15px;
      position: absolute;
      right: 15px;
      border-radius: 22px;
    `,
  };
};

interface Props {
  className?: string;
}

export const DailyHoroscopeLink = ({
  className,
}: Props & HTMLAttributes<HTMLDivElement>) => {
  const classes = useStyles();
  return (
    <div className={className}>
      <Link href={Paths.dailyHoloscopes.index()}>
        <a>
          <div css={classes.card}>
            <div tw="mr-6" css={[`color: #fff`]}>
              <p tw="text-2xs" css={[classes.text, classes.text1]}>
                毎日届く、今日の運勢❤︎
              </p>
              <p tw="text-base" css={[classes.text, classes.text2]}>
                今日の運勢ランキングを
                <br />
                チェックしよう
              </p>
            </div>
            <div tw="flex items-center">
              <p css={[classes.text, classes.text3]}>Check!</p>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};
