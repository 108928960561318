import Link from "next/link";
import { FC, HTMLAttributes } from "react";
import { PushState } from "utils/routes";

interface Props {
  readMore?: boolean;
  href?: PushState | string;
  /** @deprecated href推奨 */
  onClick?: () => void;
}

/**
 * @param readMore boolean
 * @param href string
 * @param onClick href推奨
 */
export const H2: FC<Props & HTMLAttributes<HTMLHeadElement>> = ({
  children,
  readMore,
  href = "",
  onClick,
  ...rest
}) => {
  return (
    <div tw="flex items-center h-7" {...rest}>
      <h2 tw="font-base text-lg leading-none tracking-[.53px] max-w-[80%]">
        {children}
      </h2>
      {readMore && href && (
        <Link href={href}>
          <a
            tw="font-base font-light text-xs leading-[10px] text-theme ml-auto cursor-pointer"
            onClick={onClick}
          >
            もっと見る
          </a>
        </Link>
      )}
      {readMore && !href && onClick && (
        <a
          tw="font-base font-light text-xs leading-[10px] text-theme ml-auto cursor-pointer"
          onClick={onClick}
        >
          もっと見る
        </a>
      )}
    </div>
  );
};
